export const MAIN_LANGUAGE = "en" as const;
export const DEFAULT_LANGUAGE = "default" as const;

export const LANGUAGES: Language[] = ["en", "jp", "kr"];

// Current languages (in LANGUAGES variable) are not valid hreflang values
// and it showns an error in Lighthouse test - https://developer.chrome.com/docs/lighthouse/seo/hreflang/?utm_source=lighthouse&utm_medium=devtools
// List of proper Language codes - https://en.wikipedia.org/wiki/List_of_ISO_639-1_codes (first column 639-1 codes)
export const PROPER_HREFLANG_LANGUAGES = {
  jp: "ja",
  kr: "ko",
  en: "en",
};

export const STORYBLOK_LANGUAGES: StoryblokLocal[] = ["default", "ja", "ko"];
