import React from "react";
import { MatchMediaProvider } from "./src/base/context/matchMedia";
import { LanguageProvider } from "./src/base/context/language";
import { combineComponents } from "./src/base/helpers/combineComponents";

import "./src/base/styles/main.scss";
import "slick-carousel/slick/slick.css";

const MainLayoutProvider = combineComponents(
  MatchMediaProvider,
  LanguageProvider,
);

export const wrapRootElement = ({ element }) => {
  return <MainLayoutProvider>{element}</MainLayoutProvider>;
};

export const shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition,
}): boolean => {
  const isScrollingToSection = location.hash.trim() !== "";

  if (isScrollingToSection) {
    return true;
  }

  const currentPosition = getSavedScrollPosition(location);

  setTimeout(() => {
    window.scrollTo(...(currentPosition || [0, 0]));
  }, 0);

  return false;
};
