import React, { FC, PropsWithChildren } from "react";

export const combineComponents = (
  ...components: FC<PropsWithChildren>[]
): FC<PropsWithChildren> =>
  components.reduce(
    (AccumulatedComponents, CurrentComponent) =>
      ({ children }) => (
        <AccumulatedComponents>
          <CurrentComponent>{children}</CurrentComponent>
        </AccumulatedComponents>
      ),
    ({ children }) => <>{children}</>,
  );
