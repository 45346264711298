import React, { FC, PropsWithChildren, createContext, useState } from "react";
import { DEFAULT_LANGUAGE, MAIN_LANGUAGE } from "../config/languages";

type LanguageState = {
  activeLang: Language;
  storyLang: StoryblokLocal;
  dictionary: {
    pageLabels: Record<string, string>;
  };
};

interface ContextType extends LanguageState {
  updateLang({ activeLang, storyLang, dictionary }: LanguageState): void;
}

const INITIAL_LANG_STATE: LanguageState = {
  activeLang: MAIN_LANGUAGE,
  storyLang: DEFAULT_LANGUAGE,
  dictionary: {
    pageLabels: {},
  },
};

const INITIAL_CONTEXT_STATE: ContextType = {
  ...INITIAL_LANG_STATE,
  updateLang: () => null,
};

export const LangContext = createContext<ContextType>(INITIAL_CONTEXT_STATE);

export const LanguageProvider: FC<PropsWithChildren> = ({ children }) => {
  const [langData, setLangData] = useState<LanguageState>(INITIAL_LANG_STATE);

  const updateLang = ({ activeLang, storyLang, dictionary }: LanguageState) => {
    setLangData({
      activeLang: activeLang,
      storyLang: storyLang,
      dictionary: { ...dictionary },
    });
  };

  return (
    <LangContext.Provider value={{ ...langData, updateLang }}>
      {children}
    </LangContext.Provider>
  );
};
