import React, { createContext, FC, PropsWithChildren, useContext } from "react";

import useMatchMedia from "@/base/hooks/useMediaQuery";

const LocalStateContext = createContext({
  isTablet: false,
  isDesktop: false,
  isMobile: false,
});

const LocalStateProvider = LocalStateContext.Provider;

const MatchMediaProvider: FC<PropsWithChildren> = ({ children }) => {
  const isMobile = useMatchMedia("(max-width: 575px)");
  const isTablet = useMatchMedia("(min-width: 576px) and (max-width: 991px)");
  const isDesktop = useMatchMedia("(min-width: 992px)");

  return (
    <LocalStateProvider value={{ isTablet, isDesktop, isMobile }}>
      {children}
    </LocalStateProvider>
  );
};

function useMatchMediaContext() {
  const all = useContext(LocalStateContext);
  return all;
}

export { MatchMediaProvider, useMatchMediaContext };
